@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    /* Secondary: Dark Orange */
    :root {
        --color-secondary-50: 255, 248, 240;
        --color-secondary-100: 255, 235, 214;
        --color-secondary-200: 255, 211, 163;
        --color-secondary-300: 255, 181, 102;
        --color-secondary-400: 255, 157, 51;
        --color-secondary-500: 239, 124, 0;
        --color-secondary-600: 224, 116, 0;
        --color-secondary-700: 178, 93, 0;
        --color-secondary-800: 148, 77, 0;
        --color-secondary-900: 112, 58, 0;
    }

    /* Secondary: Green */
    .theme-green {
        --color-secondary-50: 246,249,240;
        --color-secondary-100: 232,241,218;
        --color-secondary-200: 208,227,181;
        --color-secondary-300: 185,212,145;
        --color-secondary-400: 162,198,108;
        --color-secondary-500: 137,182,70;
        --color-secondary-600: 116,155,60;
        --color-secondary-700: 100,133,51;
        --color-secondary-800: 83,110,43;
        --color-secondary-900: 69,92,35;
    }

    /* Secondary: Purple */
    .theme-purple {
        --color-secondary-50: 245,242,247;
        --color-secondary-100: 229,223,246;
        --color-secondary-200: 203,191,217;
        --color-secondary-300: 178,159,198;
        --color-secondary-400: 152,126,180;
        --color-secondary-500: 133,103,166;
        --color-secondary-600: 111,83,142;
        --color-secondary-700: 88,66,113;
        --color-secondary-800: 76,57,96;
        --color-secondary-900: 63,47,80;
    }

    /* Secondary: Blue */
    .theme-blue {
        --color-secondary-50: 246,250,253;
        --color-secondary-100: 212,230,247;
        --color-secondary-200: 169,205,239;
        --color-secondary-300: 126,180,231;
        --color-secondary-400: 105,167,227;
        --color-secondary-500: 89,158,224;
        --color-secondary-600: 36,116,193;
        --color-secondary-700: 28,91,150;
        --color-secondary-800: 24,78,129;
        --color-secondary-900: 20,65,107;
    }
}

/* Headings */
.head-title { @apply font-display text-5xl lg:text-6xl xl:text-8xl font-bold leading-none sm:leading-none lg:leading-none }
.head-h1 { @apply font-heading text-5xl lg:text-6xl font-bold leading-tight sm:leading-tight lg:leading-tight }
.head-h2 { @apply font-heading text-4xl lg:text-5xl font-normal leading-tight sm:leading-tight lg:leading-tight }
.head-h3 { @apply font-heading text-3xl lg:text-4xl font-normal leading-tight sm:leading-tight lg:leading-tight }
.head-h4 { @apply font-heading text-2xl lg:text-3xl font-normal leading-tight sm:leading-tight lg:leading-tight }
.head-h5 { @apply font-heading text-xl lg:text-2xl font-normal leading-tight sm:leading-tight lg:leading-tight }
.head-h6 { @apply font-heading text-lg lg:text-xl font-normal leading-tight sm:leading-tight lg:leading-tight }

/* Body */
.body-xl { @apply text-2xl font-normal leading-normal }
.body-lg { @apply text-xl font-normal leading-normal }
.body-md { @apply text-base font-normal leading-normal }
.body-sm { @apply text-sm font-normal leading-normal }

/* Buttons - Sizing */
.btn-nav { @apply px-6 py-2 head-h6 }
.btn-sm { @apply px-6 py-2 head-h5 }
.btn-lg { @apply px-6 py-2 sm:px-8 sm:py-3 head-h4 }

@layer utilities {

    /* Text Shadow */
    .text-shadow { text-shadow: 0 2px 4px rgba(0,0,0,0.10) }
    .text-shadow-md { text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08) }
    .text-shadow-lg { text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08) }
    .text-shadow-none { text-shadow: none }

    /* Balanced Text */
    .text-balance { text-wrap: balance }
}

.copy {

    a {
        @apply font-bold text-primary-500 transition hover:text-primary-600 active:text-primary-300;
    }

    &> ul {

        @apply list-disc list-outside ml-[1.2em];

        li::marker {
            @apply text-primary-500;
        }
    }
}

.grecaptcha-badge {
    visibility: hidden !important;
}


// Animation to go from no width to full
@keyframes growWidth {
    from { width: 0  }
    to { width: 100% }
}

// Class for the Animation
.animate-grow {
    animation-name: growWidth;
    animation-duration: 4s;
}