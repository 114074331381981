@import
	"css/vendor/tailwind.scss",
	"css/setup/_all",
	"css/tools/_all",
	"css/core/_all",
	"css/components/**/*.scss";

@layer components 
{
    .underline-highlight
    {
        background-image: linear-gradient(0deg, rgba(255,157,51,1) 25%, rgba(255,255,255,0) 25%);
    }
}