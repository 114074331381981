/* ==========================================================================
	Back To Top
	========================================================================== */
	.BackToTop {

	// Initially non visible
	visibility: hidden;
	opacity: 0;

	// Transition
	transition: opacity .3s 0s, visibility 0s .3s;

	&.cd-is-visible,
	&.cd-fade-out,
	&:hover {
		transition: opacity .3s 0s, visibility 0s 0s;
	}

	// The button becomes visible
	&.cd-is-visible {

		visibility: visible;
		opacity: .7;
	}

	// While scrolling down, button is out of focus
	&.cd-fade-out {

		opacity: .5;
	}

	// Button visible when hovering
	&:hover {

		opacity: 1;
	}

	@media only screen and (min-width: 768px) {

		.cd-top {

			right: 20px;
			bottom: 20px;
		}
	}

	@media only screen and (min-width: 1024px) {

		.cd-top {
			height: 60px;
			width: 60px;
			right: 30px;
			bottom: 30px;
		}
	}
}