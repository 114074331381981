html {

}

*,
*:before,
*:after {

}

/* for AlpineJs */
[x-cloak] {
  display: none !important;
}


#wrapper {

	overflow: hidden;
}


#elc-cookie-consent .elc-button-link {
  background-color: #EF7C00 !important;

}

#elc-accept-link {
  border-color: #EF7C00 !important;
  color: #EF7C00 !important;
}
#elc-detail-link {
  color: #EF7C00 !important;
}
.elc-header-description {
  padding: 8px 0px;
  font-size: 16px !important;
}

.elc-primary-heading {
  display: none !important;
}