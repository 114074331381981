@media print {

	////////////////////////////////////////
	//////////////// Body //////////////////
	////////////////////////////////////////

	body {
		font-size: 12pt;
		line-height: 1.25em;
	}

	/////////////////////////////////////////
	/////////////// Headings ////////////////
	/////////////////////////////////////////

	h1 {
		font-size: 36pt;
		line-height: 1.25em;
	}

	h2 {
		font-size: 24pt;
		line-height: 1.25em;
	}


	h3 {
		font-size: 18pt;
		line-height: 1.25em;
	}

	h4 {
		font-size: 14pt;
		line-height: 1.25em;
	}

	/////////////////////////////////////////
	/////////////// Generic /////////////////
	/////////////////////////////////////////

	body {
		background: none;
	}

	header {
		display: none;
	}

	article a:after {
		content: " [" attr(href) "] ";
	}

	aside a:after {
		display: none;
	}

}
